import network from '../network/network';
import { getHostName } from './domain';

import {
  isDebug,
  isDebugTopAd,
  isNotNativeAd,
  isNativeAd,
  adHasExistingContainer,
  isMainAndCategoryPage,
} from '../helpers/utils';
import log from '../helpers/log';

let savedAccConfig: IAdsConfig | undefined;
const highestIds: HighestIds = {
  responsive: 0,
  intext_ad: 0,
};
const processedDivIds = new Set<string>();

export const modifyMainCategoryPlacementsDivs = (): void => {
  document.querySelectorAll<HTMLElement>("[id^='responsive_'], [id^='intext_ad_']").forEach((div) => {
    if (processedDivIds.has(div.id)) return;

    const match = div.id.match(/(responsive|intext_ad)_(\d+)(-.+)?/);
    if (!match) return;

    const [_, type, , suffix = ''] = match;

    highestIds[type] += 1;
    const newId = `${type}_${highestIds[type]}${suffix}`;

    div.id = newId;
    div.setAttribute('ad-placement', highestIds[type] >= 9 ? `${type}_X` : newId.split('-')[0]);
    processedDivIds.add(div.id);
  });
};

const modifyArticlesPlacementsDivs = (divId: string, placement: string) => {
  let placementDivs = document.getElementById(divId);
  if (placementDivs) {
    placementDivs.setAttribute('ad-placement', placement);
  }
};

const fetchAdsConfig = async () => {
  const safeHost = getHostName();
  const fallbackUri = `${process.env.FALLBACK_CONFIG_BASE_URL}${safeHost}.json`;
  const resource = `/config/get${safeHost}`;
  let response;
  try {
    response = await network.get(resource);
  } catch (e) {
    console.warn('Error while loading ad configuration. Applying fallback', isDebug() ? e : '');
    response = await network.get(fallbackUri);
  }
  return response;
};

const getModifiedConfig = async (pageNo: number, serverLoadedConfig?: IAdsConfig ) => {
  savedAccConfig =
  savedAccConfig ||
    (serverLoadedConfig && Object.keys(serverLoadedConfig).length > 0 ? serverLoadedConfig : await getConfig());

  log(`ACC Config ${!!serverLoadedConfig ? "(serverLoadedConfig)" : ''}:`, savedAccConfig);

  if (!(savedAccConfig?.ads && savedAccConfig.ads.length > 0)) {
    throw new Error('Error ACC config is missing');
  }

  if (savedAccConfig) {
    const accConfigCopy = typeof structuredClone === 'function' ? structuredClone(savedAccConfig) : JSON.parse(JSON.stringify(savedAccConfig));    
    return await modifyPlacementsInConfig(accConfigCopy, pageNo);
  }
};

const getConfig = async () => {
  try {
    return await fetchAdsConfig();
  } catch (e) {
    console.error('Failed to retrieve ads configuration.');
  }
};

const addExtraPlacementsToConfig = (config: any, placement: string, processed: any) => {
  log('addExtraPlacements if needed ', placement);

  const ads = [...config.ads];
  placement = `${placement}${placement.includes('intext') ? '_ad' : ''}`;
  const xSlot = ads.find((adSlot: any) => {
    const target = adSlot.target;
    return target.includes(`${placement}`) && (target.includes('-x') || target.includes('_x'));
  });

  const placementDivs: any[] | NodeListOf<Element> = placement
    ? document.querySelectorAll(`[id^="${placement}"]`) || []
    : [];

  if (xSlot && placementDivs.length > 0) {
    for (let no = 0; no < placementDivs.length; no++) {
      const target = placementDivs[no].id;
      const newTarget: String = target && target.includes(placement) && target.substring(0, target.indexOf('-'));
      const slotExists = ads.some((adSlot: any) => adSlot.target === `${newTarget}`);

      if (slotExists) {
        // Slot already exists. Will not add;
        continue;
      }
      const newPlacement = `${placement}_X`;

      if (newTarget) {
        log(`Adding target ${newTarget} and placement ${newPlacement} from ${target}. div-id: ${placementDivs[no].id}`);

        ads.push({
          ...xSlot,
          target: newTarget,
          placement: newPlacement,
        });
      } else {
        log(`Couldn't set intext_ad_X for ${target}, on index ${no + 1}`);
      }
    }
  } else {
    log(`${placementDivs.length} ${placement} found, so no ${placement}_X will be set`);
  }
  log('ads after adding extra:', ads);

  return { ...config, ads };
};

const modifyPlacementsInConfig = (config: any, pageNo: number) => {
  if (config && config.ads) {
    const ads = config.ads.map((ad: any) => {
      if (
        ad.placement &&
        pageNo > 2 &&
        !isMainAndCategoryPage() &&
        (ad.placement.match(/^intext/) || ad.placement.match(/^responsive/))
      ) {
        const placement = ad.placement.match(/^intext/) ? 'intext_ad' : 'responsive';
        ad.placement = `${placement}_X`;
        let divId = `${ad.target}-${window.ama?.adsUniqueId || window.adsUniqueId}`;
        modifyArticlesPlacementsDivs(divId, ad.placement);
      }
      return ad;
    });
    if (ads) {
      config.ads = ads;
    }
  }
  return config;
};

type Config = {
  ads: any[];
};

const applyFilter = (config: Config, predicate: (ad: any) => boolean) => {
  const filteredAds = config.ads.filter(predicate);
  return { ...config, ads: filteredAds };
};

const filter = (config: any, isNative = false) => {
  config.ads =
    config.enableWallpaperAds === 'true' || isDebugTopAd()
      ? config.ads
      : config.ads.filter((ad: any) => ad.placement !== 'top_ad');
  const nativePredicate = isNative ? isNativeAd : isNotNativeAd;
  const filters = [nativePredicate, adHasExistingContainer];
  return filters.reduce((updatedConfig, filter) => applyFilter(updatedConfig, filter), config);
};

const adConfig = {
  get: getConfig,
  getModifiedConfig: getModifiedConfig,
  addExtraPlacementsToConfig,
  filter: filter,
};

export default adConfig;
